//CSS
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter as Router } from "react-router-dom";
import { LanguageProvider } from "./context/LanguageContext";
import CryptoUserProvider from "./context/cryptoUserContext";
import Main from "./main";



function App() {
  return (
    <CryptoUserProvider>
      <LanguageProvider>
        <Router>
          <Main />
        </Router>
      </LanguageProvider>
    </CryptoUserProvider>
  );
}

export default App;
