export const formatAddress = (address) => {
  if (typeof address === "string") {
    if (address.length > 8) {
      return address.substr(0, 8) + "...";
    }
  }
  return address;
};

export const cryptoToUsd = (oneCryptoToUSDPrice, usdPrice) => {
  return oneCryptoToUSDPrice !== "400" && oneCryptoToUSDPrice !== "0"
    ? Number((1 / oneCryptoToUSDPrice) * usdPrice)
    : 0;
}