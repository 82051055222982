import {
  Row,
  Col,
  Form,
  Navbar,
  Nav,
  Container,
  Button,
} from "react-bootstrap";
import Footer from "./Footer";
import React, { useEffect, useState } from "react";
import Constants from "../constants/Constants";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { CryptoUserContext } from "../context/cryptoUserContext";
import { cryptoToUsd } from "../utils/utils";
import MkdSDK from "../utils/MkdSDK";
import { useNavigate } from "react-router-dom";

let sdk = new MkdSDK();

function PreSubscribe() {
  const navigate = useNavigate();
  const {
    state,
    dispatch,
    logout,
    connect,
    getCorrectProvider,
    sendTransactionRaw,
    fetchBnbBalance,
  } = React.useContext(CryptoUserContext);

  const [subscriptionType, setSubscriptionType] = useState("basic");
  const [redTokens, setRedTokens] = useState(0);
  const [usdPrice, setUsdPrice] = useState("0");
  const [bnbAmount, setBnbAmount] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const [oneCryptoToUSDPrice, setOneCryptoToUSDPrice] = useState("400");
  const [bnbBalance, setBnbBalance] = useState(0);
  const [show, setShow] = useState(false);

  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  useEffect(() => {
    if (usdPrice > 0 && subscriptionType) {
      let subType = -1;
      if (subscriptionType === "institutional") {
        subType = 3;
      } else if (subscriptionType === "creator") {
        subType = 2;
      } else if (subscriptionType === "basic") {
        subType = 1;
      }

      if (subType === -1) {
        return;
      }

      setBnbAmount(cryptoToUsd(oneCryptoToUSDPrice, usdPrice));

      (async function () {
        try {
          const result = await sdk.bnbToRed(
            subType,
            cryptoToUsd(oneCryptoToUSDPrice, usdPrice)
          );
          if (result.red) {
            setRedTokens(Number(result.red).toFixed(2));
          } else {
            setRedTokens(0);
          }
        } catch (error) {
          console.log("ERROR", error);
        }
      })();
    } else {
      setRedTokens(0);
    }
  }, [usdPrice, subscriptionType]);

  useEffect(() => {
    if (!state.isLoading) {
      if (!state.cryptoUser) {
        setShow(true);
      }
      (async function () {
        try {
          const result = await sdk.bnbToUSD();
          if (result.one_crypto_to_usd_price) {
            setOneCryptoToUSDPrice(result.one_crypto_to_usd_price);
          } else {
            //TODO make better
            console.log("Fail to fetch price");
          }

          if (state.cryptoUser) {
            const balance = await fetchBnbBalance(state.cryptoUser);
            setBnbBalance(balance);
          }
        } catch (error) {
          console.log("ERROR", error);
        }
      })();
    }
  }, [state]);

  const handleSubmit = async () => {
    let web3 = await getCorrectProvider();
    let bnb = 0;
    const chainId = await web3.eth.getChainId();

    if (!(chainId === 97 || chainId === 56)) {
      toast.error("Please switch to Binance Smart Chain");
      return;
    }

    setIsLoading(true);

    if (!state.cryptoUser) {
      toast.error("Please Login");
      setIsLoading(false);
      setShow(true);
      return;
    }

    if (
      usdPrice <= 0 ||
      (subscriptionType !== "institutional" &&
        subscriptionType !== "creator" &&
        subscriptionType !== "basic")
    ) {
      toast.error("Invalid input");
      setIsLoading(false);
      return;
    }

    let subType = -1;
    let receivingContract = "";

    if (subscriptionType === "institutional") {
      subType = 3;
      receivingContract = Constants.RED_PRESUB_CONTRACT;
      if (bnbAmount < 26) {
        toast.error("Institutional minimum limit is 26 BNB");
        setIsLoading(false);
        return;
      }
    } else if (subscriptionType === "creator") {
      subType = 2;
      receivingContract = Constants.RED_PRESUB_CONTRACT;
      if (bnbAmount < 14) {
        toast.error("Creator minimum limit 14 BNB");
        setIsLoading(false);
        return;
      }
    } else if (subscriptionType === "basic") {
      subType = 1;
      receivingContract = Constants.RED_PRESUB_CONTRACT;
      if (bnbAmount <= 0 || bnbAmount > 13) {
        toast.error("Basic limit 0 - 13 BNB");
        setIsLoading(false);
        return;
      }
    }

    if (subType === -1) {
      toast.error("Invalid input");
      setIsLoading(false);
      return;
    }

    const id = toast.loading("Please wait...");

    try {
      const result = await sdk.redToBnb(subType, redTokens);
      if (result.bnb) {
        bnb = result.bnb;
      } else {
        toast.dismiss(id);
        toast.error("Failed to send request");
        setIsLoading(false);
        return;
      }
    } catch (error) {
      console.log("ERROR", error);
    }

    toast.update(id, { render: "Verifying token amount...", type: "info" });

    const tx_data = JSON.stringify({
      red_tokens: redTokens,
      usd: oneCryptoToUSDPrice * bnb,
      crypto_amount: bnb,
      crypto_type: "BNB",
      subscription_type: subType,
    });

    console.log("Transaction Data", tx_data);

    try {
      const transactionResult = await sdk.createTransaction({
        user_id: state.userId,
        wallet_id: state.cryptoUser,
        amount: bnb,
        coin_type_symbol: "BNB",
        tx_hash: "N/A",
        type: 1,
        data: tx_data,
      });
      toast.update(id, {
        render: "Checking transaction status...",
        type: "info",
      });

      console.log(transactionResult);

      const transactionId = transactionResult.id;

      const transactionUpdateResult = await sdk.transactionUpdate({
        tx_id: transactionId,
        status: "payment-processing",
        wallet_id: state.cryptoUser,
      });

      await sendTransaction(
        state.cryptoUser,
        bnb,
        transactionId,
        receivingContract,
        id
      );
    } catch (error) {
      console.log("ERROR", error);
      toast.dismiss(id);
      toast.error("Error in sending request");
      setIsLoading(false);
      return;
    }
  };

  async function sendTransaction(
    from,
    amount,
    transactionId,
    receivingContract,
    toastId
  ) {
    if (!from) {
      toast.dismiss(toastId);
      toast.error("Wallet not connected to perform the transaction!");
      setIsLoading(false);
      return;
    }

    if (amount <= 0) {
      toast.dismiss(toastId);
      toast.error("Transaction cancelled!");
      setIsLoading(false);
      return;
    }

    //???
    amount = amount + "";

    const result = await sendTransactionRaw(
      from,
      amount,
      transactionId,
      receivingContract
    );
    if (result.error) {
      if (result.status_problem) {
        setIsLoading(false);
        await sdk.transactionUpdate({
          tx_id: transactionId,
          status: result.status_problem,
          wallet_id: from,
        });
        toast.dismiss(toastId);
        toast.error(result.message);
      }
    } else {
      setIsLoading(false);
      toast.update(toastId, {
        render: "Verifying transaction...",
        type: "info",
      });
      await sdk.transactionUpdate({
        tx_id: transactionId,
        status: "complete",
        wallet_id: from,
      });
      toast.dismiss(toastId);
      toast.success("Subscription successful!");
      navigate("/profile");
    }
  }

  return (
    <div
      className="bg-black"
      style={{
        color: "white",
        background: "rgb(0,0,0)",
        background:
          "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(78,30,30,1) 50%, rgba(0,0,0,1) 100%)",
      }}
    >
      <Navbar collapseOnSelect expand="lg" bg="black" variant="dark">
        <Container>
          <Navbar.Brand href="/">
            <img
              alt="RedEye Logo"
              src="/assets/images/nav-red-eye.svg"
              height="40"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              <Nav.Link href="/">Home</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link href="/home">Marketplace</Nav.Link>
            </Nav>
            <Nav>
              {state.cryptoUser && (
                <Nav.Link href="/profile">My Profile</Nav.Link>
              )}
            </Nav>
            <Nav>
              {!state.cryptoUser ? (
                <Button onClick={handleOpen} className="mx-3 btn btn-danger">
                  Connect Wallet
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    logout();
                    navigate("/");
                  }}
                  className="mx-3 btn btn-danger"
                >
                  Logout
                </Button>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container>
        {/* TODO: Own component? */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Connect Wallet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              onClick={() => {
                connect("Metamask");
                handleClose();
              }}
              style={{
                cursor: "pointer",
                width: "200px",
                border: "1px solid #fff",
                borderRadius: "10px",
                padding: "20px 30px 0",
                margin: "0 auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src="/MetaMask_Fox.png" style={{ width: "100%" }} />
              <p style={{ fontWeight: "bolder" }}>Metamask</p>
            </div>
            <div
              onClick={() => {
                connect("WalletConnect");
                handleClose();
              }}
              className="mt-2"
              style={{
                cursor: "pointer",
                width: "200px",
                border: "1px solid #fff",
                borderRadius: "10px",
                padding: "20px 30px 0",
                margin: "0 auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src="/wallet_connect.ico" style={{ width: "100%" }} />
              <p style={{ fontWeight: "bolder" }}>Wallet Connect</p>
            </div>
          </Modal.Body>
        </Modal>
        <Row className="my-5">
          <h1 className="header-text-font">Pre-subscription</h1>
          <Row style={{ display: "contents" }}>
            <Col lg={6} className="mt-3">
              <Form>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>
                    We only accept BNB{" "}
                    <img
                      src="./assets/images/bnb-coin.svg"
                      style={{ width: "20px", height: "20px" }}
                    />{" "}
                  </Form.Label>
                  <br />
                  <Form.Label>
                    Available balance: <b>{bnbBalance} BNB</b>{" "}
                    <img
                      src="./assets/images/bnb-coin.svg"
                      style={{ width: "20px", height: "20px" }}
                    />{" "}
                    {oneCryptoToUSDPrice !== "400"
                      ? "[ $ " +
                        Number(oneCryptoToUSDPrice * bnbBalance).toFixed(2) +
                        " ]"
                      : ""}
                  </Form.Label>
                </Form.Group>
                <hr />
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput0"
                >
                  <Form.Label>Please Select Your Subscription Type</Form.Label>
                  <Form.Select
                    onChange={(e) => setSubscriptionType(e.target.value)}
                    aria-label="Default select example"
                  >
                    <option value="basic">Basic</option>
                    <option value="institutional" disabled={true}>
                      Institutional (closed)
                    </option>
                    <option value="creator" disabled={true}>
                      Creator (closed)
                    </option>
                  </Form.Select>
                </Form.Group>
                {subscriptionType === "institutional" ? (
                  <div className="text-center preSubInfoBox">
                    <h6>For Institutional subs</h6>
                    <p className="mb-0">
                      1 BNB{" "}
                      <img
                        src="./assets/images/bnb-coin.svg"
                        style={{ width: "20px", height: "20px" }}
                      />{" "}
                      = 4,00,000 RED{" "}
                      <img
                        src="/assets/images/red-eye.svg"
                        style={{ width: "30px", height: "30px" }}
                      />
                    </p>
                    <p className="mb-0">
                      Pre-sub amount &#8805; 26 BNB{" "}
                      <img
                        src="./assets/images/bnb-coin.svg"
                        style={{ width: "20px", height: "20px" }}
                      />{" "}
                      {oneCryptoToUSDPrice !== "400"
                        ? "[ $ " +
                          Number(oneCryptoToUSDPrice * 26).toFixed(2) +
                          " ]"
                        : ""}
                    </p>
                  </div>
                ) : subscriptionType === "creator" ? (
                  <div className="text-center preSubInfoBox">
                    <h6>For Creator subs</h6>
                    <p className="mb-0">
                      1 BNB{" "}
                      <img
                        src="./assets/images/bnb-coin.svg"
                        style={{ width: "20px", height: "20px" }}
                      />{" "}
                      = 1,00,000 RED{" "}
                      <img
                        src="/assets/images/red-eye.svg"
                        style={{ width: "30px", height: "30px" }}
                      />
                    </p>
                    <p className="mb-0">
                      Pre-sub range 14 BNB <sub>min</sub> - 24 BNB{" "}
                      <sub>max</sub>{" "}
                      <img
                        src="./assets/images/bnb-coin.svg"
                        style={{ width: "20px", height: "20px" }}
                      />
                    </p>
                    <p className="mb-0">
                      {oneCryptoToUSDPrice !== "400"
                        ? "[ $ " + Number(oneCryptoToUSDPrice * 14).toFixed(2)
                        : ""}{" "}
                      to{" "}
                      {oneCryptoToUSDPrice !== "400"
                        ? " $ " +
                          Number(oneCryptoToUSDPrice * 24).toFixed(2) +
                          " ]"
                        : ""}
                    </p>
                  </div>
                ) : (
                  <div className="text-center preSubInfoBox">
                    <h6>For Basic subs</h6>
                    <p className="mb-0">
                      1 BNB{" "}
                      <img
                        src="./assets/images/bnb-coin.svg"
                        style={{ width: "20px", height: "20px" }}
                      />{" "}
                      = 66,666.67 RED{" "}
                      <img
                        src="/assets/images/red-eye.svg"
                        style={{ width: "30px", height: "30px" }}
                      />
                    </p>
                    <p className="mb-0">
                      Pre-sub amount ≤ 13 BNB{" "}
                      <img
                        src="./assets/images/bnb-coin.svg"
                        style={{ width: "20px", height: "20px" }}
                      />{" "}
                      {oneCryptoToUSDPrice !== "400"
                        ? "[ $ " +
                          Number(oneCryptoToUSDPrice * 13).toFixed(2) +
                          " ]"
                        : ""}
                    </p>
                  </div>
                )}
                <Form.Group
                  className="mt-2 mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>
                    Enter the amount in USD($) you wish to spend
                  </Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    value={usdPrice}
                    onChange={(e) => setUsdPrice(e.target.value)}
                    placeholder="$ 14900.0"
                  />
                </Form.Group>
                <div className="text-center preSubInfoBox">
                  <p className="mb-0">
                    You will spend{" "}
                    {oneCryptoToUSDPrice !== "400" &&
                    oneCryptoToUSDPrice !== "0"
                      ? Number((1 / oneCryptoToUSDPrice) * usdPrice).toFixed(4)
                      : 0}{" "}
                    BNB{" "}
                    <img
                      src="./assets/images/bnb-coin.svg"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </p>
                  <p className="mb-0">
                    You will receive <b>{redTokens} RED</b>{" "}
                    <img
                      src="./assets/images/red-eye.svg"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </p>
                </div>
              </Form>
              <h2 className="mt-3 header-text-font">Agreement</h2>
              <p>
                By submitting this form you agree to the Red-eye
                Pre-subscription terms and conditions. Any fees, arising from
                this transaction are to be fully covered by the Subscriber.
              </p>
              <Button
                className="btn red-gradient-btn px-5"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? "Wait..." : "Submit"}
              </Button>
              <br />
              <br />
              *To see your RED Balance make sure to import RED token to your
              wallet.
              <br />
              RED contract address: <b>{Constants.RED_TOKEN_CONTRACT}</b>
              <br />
              and see it on{" "}
              <a
                href={`https://bscscan.com/address/${Constants.RED_TOKEN_CONTRACT}`}
                target="_blank"
              >
                BSC scan
              </a>
              .
            </Col>
            <Col lg={6} className="mt-5">
              <p>
                A pre - subscription allows you to create an early red-eye
                account and receive a limited Number of RED tokens included with
                the Subscription. This is not a Pre-sale or Token sale, RED
                Tokens are specifically needed to Sell, Buy, Mint and Curate
                content within the RedEye eco-system. Outside of the Pre -
                subscription these will only be available via exchanges once
                red-eye is fully open to all users and only a limited amount of
                them will be available to the market at large. The
                pre-subcriptions plans are as follows:{" "}
              </p>
              <ol>
                <li>
                  <b>Institutional</b>: designed for News Orgs and large
                  creators that will be creating, minting or consuming a large
                  amount of content.{" "}
                  <b>
                    [Starting Price is set to 26 BNB and comes with +10,400,000
                    RED tokens]
                  </b>
                  <br />
                  Allows users to register Blacklisted names for use within RED
                  ecosystem.
                </li>
                <li>
                  <b>Creator</b>: Designed for content creators amateur or
                  professional journalists who will be creating large amounts of
                  content or consuming content for their project's needs.{" "}
                  <b>
                    [Starting Price is set at 14 BNB with a MAX Cap of 24 BNB
                    Starts with 1,400,000 RED]
                  </b>
                </li>
                <li>
                  <b>Basic User</b>: A regular user account that will be used to
                  consume content and occasionally mint random videos as they
                  develop over time.{" "}
                  <b>
                    [No starting BNB Minimum, Maximum RED Cap is set to 13 BNB
                    or total of 933,324 RED]
                  </b>
                </li>
              </ol>
            </Col>
          </Row>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default PreSubscribe;
