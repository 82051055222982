import React from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Home from "./components/Home";
import PreSubscribe from "./components/PreSubscribe";
// import Marketplace from "./components/Marketplace";
// import Profile from "./components/Profile";
// import VideoPage from "./components/VideoPage";
// import PrivacyPolicy from "./components/PrivacyPolicy";
// import TermsOfService from "./components/TermsOfService";
// import Stake from "./components/Stake";
// import Faq from "./components/FAQ";

function Main() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="presubscribe" element={<PreSubscribe />} />
        {/*
        <Route
          path="home"
          element={
            <Marketplace
              connect={connect}
              logout={logout}
              cryptoUser={cryptoUser}
              provider={provider}
            />
          }
        />
        <Route
          path="video/:id"
          element={
            <VideoPage
              connect={connect}
              logout={logout}
              cryptoUser={cryptoUser}
              provider={provider}
            />
          }
        />
        <Route
          path="profile"
          element={
            <Profile
              connect={connect}
              logout={logout}
              cryptoUser={cryptoUser}
              provider={provider}
            />
          }
        />
        <Route
          path="privacy"
          element={
            <PrivacyPolicy
              connect={connect}
              logout={logout}
              cryptoUser={cryptoUser}
              provider={provider}
            />
          }
        />
        <Route
          path="terms-of-service"
          element={
            <TermsOfService
              connect={connect}
              logout={logout}
              cryptoUser={cryptoUser}
              provider={provider}
            />
          }
        />
        <Route
          path="governance"
          element={
            <Stake
              connect={connect}
              logout={logout}
              cryptoUser={cryptoUser}
              provider={provider}
            />
          }
        />
        <Route
          path="faq"
          element={
            <Faq
              connect={connect}
              logout={logout}
              cryptoUser={cryptoUser}
              provider={provider}
            />
          }
        /> */}
      </Routes>
      <ToastContainer theme="dark" />
    </>
  );
}

export default Main;
