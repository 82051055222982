const Constants = {
  RED_TOKEN_CONTRACT: process.env.REACT_APP_TOKEN_CONTRACT,
  RED_PRESUB_CONTRACT: process.env.REACT_APP_PRESUB_CONTRACT,
  RED_NFT_CONTRACT: process.env.REACT_APP_NFT_CONTRACT,
  RED_MARKETPLACE_CONTRACT: process.env.REACT_APP_MARKETPLACE_CONTRACT,
  RED_GOVERNANCE_CONTRACT: process.env.REACT_APP_GOVERNANCE_CONTRACT,
  DEEP_LINK: process.env.REACT_APP_DEEP_LINK,
};

export default Constants;
