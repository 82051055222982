import React, { useState, useReducer } from "react";
import MkdSDK from "../utils/MkdSDK";
import { toast } from "react-toastify";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Constants from "../constants/Constants";

export const CryptoUserContext = React.createContext();

const initialState = {
  isAuthenticated: false,
  cryptoUser: null,
  userId: null,
  walletType: null,
  isLoading: true,
};

const wcProvider = new WalletConnectProvider({
  rpc: {
    97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    56: "https://bsc-dataseed.binance.org/",
  },
});

const reducer = (state, action) => {
  switch (action.type) {
    case "SAVEUSER":
      localStorage.setItem("crypto_user", JSON.stringify(action.payload));
      return {
        ...state,
        isAuthenticated: true,
        cryptoUser: action.payload.walletId,
        userId: action.payload.id,
      };
    case "SAVEWALLETTYPE":
      localStorage.setItem("wallet_type", action.payload);
      return {
        ...state,
        walletType: action.payload,
      };
    case "DELETEUSER":
      localStorage.removeItem("crypto_user");
      localStorage.removeItem("wallet_type");
      return {
        ...state,
        cryptoUser: null,
        walletType: null,
      };
    case "LOGOUT":
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        cryptoUser: null,
        userId: null,
        walletType: null,
      };
    case "COMPLETELOADING":
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

let sdk = new MkdSDK();

const CryptoUserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [provider, setProvider] = useState(window.ethereum);

  //TODO Refactor connect
  //Send in Metamask or WalletConnect
  async function connect(walletProvider) {
    if (walletProvider === "Metamask") {
      dispatch({
        type: "SAVEWALLETTYPE",
        payload: "METAMASK",
      });

      let metamaskProvider = window.ethereum;

      if (typeof metamaskProvider !== "undefined") {
        const id = toast.loading("Connecting...");

        setProvider(metamaskProvider);

        //I guess this attempt to connect to metamask
        metamaskProvider
          .request({ method: "eth_requestAccounts" })
          .then(async (accounts) => {
            console.log("accounts", accounts);
            const result = await sdk.register(accounts);
            console.log("register", result);
            toast.success("Wallet connected");
            dispatch({
              type: "SAVEUSER",
              payload: result,
            });
            toast.dismiss(id);
          })
          .catch((err) => {
            toast.dismiss(id);
            toast.error("Failed to connect wallet");
            toast.error(err);
          });
      } else {
        //Not sure what this does
        console.log("Deep Linking");
        window.open(Constants.DEEP_LINK);
      }
      //TODO refactor into strategy pattern
    } else if (walletProvider === "WalletConnect") {
      dispatch({
        type: "SAVEWALLETTYPE",
        payload: "WALLETCONNECT",
      });

      await wcProvider.enable();

      const web3 = new Web3(wcProvider);

      setProvider(wcProvider);

      //Get accounts
      //TODO What if BSC?
      const accounts = await web3.eth.getAccounts();

      if (Array.isArray(accounts) && accounts.length !== 0) {
        const id = toast.loading("Connecting...");
        const result = await sdk.register(accounts);
        // console.log("register", result);
        toast.success("Wallet connected");
        dispatch({
          type: "SAVEUSER",
          payload: result,
        });
        toast.dismiss(id);
      } else {
        toast.error("Failed to connect wallet");
      }
    }
  }

  async function getCorrectProvider() {
    let web3 = new Web3(provider);

    //TODO Refactor this better
    if (!provider) {
      if (state.walletType === "METAMASK") {
        web3 = new Web3(window.ethereum);
        await setTimeout(() => {}, 0);
      } else if (state.walletType === "WALLETCONNECT") {
        await wcProvider.enable();
        web3 = new Web3(wcProvider);
      }
    } else {
      await setTimeout(() => {}, 0);
    }

    return web3;
  }

  async function fetchBnbBalance(walletAddress) {
    let web3 = await getCorrectProvider();
    console.log("web3", web3);
    try {
      const balance = await web3.eth.getBalance(walletAddress);
      return web3.utils.fromWei(balance, "ether");
    } catch (error) {
      console.log("ERROR WEB3 getBalance", error);
      return 0;
    }
  }

  async function sendTransactionRaw(
    from,
    amount,
    transactionId,
    receivingContract
  ) {
    let web3 = await getCorrectProvider();

    console.log("Send Transaction Log", {
      from: from,
      to: receivingContract,
      value: web3.utils.toWei(amount),
    });
    try {
      return web3.eth
        .sendTransaction({
          from: from,
          to: receivingContract,
          value: web3.utils.toWei(amount),
        })
        .on("error", async (err) => {
          if (err.code === 4001) {
            return {
              error: true,
              status_problem: "rejected",
              message:
                "Pre-subscription could not be made because the transaction was rejected!",
            };
          } else {
            return {
              error: true,
              status_problem: "payment-failure",
              message:
                "Error! An unknown error while processing your subscription!",
            };
          }
        })
        .then(async (receipt) => {
          const tx_hash = receipt.transactionHash;
          const result = await sdk.hashUpdate({
            tx_hash: tx_hash,
            tx_id: transactionId,
          });
          return {
            error: false,
            message: "Success",
          };
        });
    } catch (error) {
      console.log("ERROR WEB3 send Transaction Raw", error);
      return {
        error: true,
        message: error.message,
      };
    }
  }

  async function logout() {
    if (provider && typeof provider.disconnect === "function") {
      await provider.disconnect();
    }
    dispatch({
      type: "LOGOUT",
    });
    toast.success("Wallet disconnected");
  }

  React.useEffect(() => {
    const user = localStorage.getItem("crypto_user");
    const wallet_type = localStorage.getItem("wallet_type");

    if (user) {
      dispatch({
        type: "SAVEUSER",
        payload: JSON.parse(user),
      });
    }

    if (wallet_type) {
      dispatch({
        type: "SAVEWALLETTYPE",
        payload: wallet_type,
      });
    }
    dispatch({
      type: "COMPLETELOADING",
    });
  }, []);

  return (
    <CryptoUserContext.Provider
      value={{
        state,
        dispatch,
        logout,
        connect,
        fetchBnbBalance,
        sendTransactionRaw,
        getCorrectProvider,
      }}
    >
      {children}
    </CryptoUserContext.Provider>
  );
};

export default CryptoUserProvider;
