export default function MkdSDK() {
  this._baseurl = "https://developmentapi.redeyed.io";
  this._project_id = "redeyed";
  this._secret = "03hxjsyng8J-";
  this._table = "";
  this._custom = "";
  this._method = "";

  const raw = this._project_id + ":" + this._secret;
  let base64Encode = btoa(raw);

  this.login = async function (email, password, role) {
    const result = await fetch(this._baseurl + "/v2/api/lambda/login", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-project": base64Encode,
      },
      body: JSON.stringify({
        email,
        password,
        role,
      }),
    });
    const json = await result.json();

    if (result.status === 401) {
      throw new Error(json.message);
    }

    if (result.status === 403) {
      throw new Error(json.message);
    }
    return json;
  };

  this.getHeader = function () {
    return {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "x-project": base64Encode,
    };
  };

  this.baseUrl = function () {
    return this._baseurl;
  };
  this.uploadUrl = function () {
    return this._baseurl + "/v2/api/lambda/upload";
  };

  this.upload = function (payload) {};
  this.getProfile = async function () {
    const result = await fetch(this._baseurl + "/v2/api/lambda/profile", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "x-project": base64Encode,
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    const json = await result.json();

    if (result.status === 401) {
      throw new Error(json.message);
    }

    if (result.status === 403) {
      throw new Error(json.message);
    }
    return json;
  };

  this.bnbToRed = async function (subType, bnbAmount) {
    const result = await fetch(this._baseurl + "/v2/api/custom/bnb-to-red", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-project": base64Encode,
      },
      body: JSON.stringify({
        subType,
        bnbAmount,
      }),
    });
    const json = await result.json();

    if (result.status === 401) {
      throw new Error(json.message);
    }

    if (result.status === 403) {
      throw new Error(json.message);
    }
    return json;
  };

  this.redToBnb = async function (subType, redTokens) {
    const result = await fetch(this._baseurl + "/v2/api/custom/red-to-bnb", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-project": base64Encode,
      },
      body: JSON.stringify({
        subType,
        redTokens,
      }),
    });
    const json = await result.json();

    if (result.status === 401) {
      throw new Error(json.message);
    }

    if (result.status === 403) {
      throw new Error(json.message);
    }
    return json;
  };

  this.bnbToUSD = async function () {
    const result = await fetch(this._baseurl + "/v2/api/custom/bnb-to-usd", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-project": base64Encode,
      },
      body: JSON.stringify({
        type: "BNB",
      }),
    });
    const json = await result.json();

    if (result.status === 401) {
      throw new Error(json.message);
    }

    if (result.status === 403) {
      throw new Error(json.message);
    }
    return json;
  };

  this.createTransaction = async function (payload) {
    const result = await fetch(
      this._baseurl + "/v2/api/custom/transaction/create",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-project": base64Encode,
        },
        body: JSON.stringify(payload),
      }
    );
    const json = await result.json();

    if (result.status === 401) {
      throw new Error(json.message);
    }

    if (result.status === 403) {
      throw new Error(json.message);
    }
    return json;
  };

  this.transactionUpdate = async function (payload) {
    const result = await fetch(
      this._baseurl + "/v2/api/custom/transaction/status/update",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-project": base64Encode,
        },
        body: JSON.stringify(payload),
      }
    );
    const json = await result.json();

    if (result.status === 401) {
      throw new Error(json.message);
    }

    if (result.status === 403) {
      throw new Error(json.message);
    }
    return json;
  };

  this.hashUpdate = async function (payload) {
    const result = await fetch(
      this._baseurl + "/v2/api/custom/transaction/hash/update",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-project": base64Encode,
        },
        body: JSON.stringify(payload),
      }
    );
    const json = await result.json();

    if (result.status === 401) {
      throw new Error(json.message);
    }

    if (result.status === 403) {
      throw new Error(json.message);
    }
    return json;
  };

  this.check = async function (role) {
    const result = await fetch(this._baseurl + "/v2/api/lambda/check", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-project": base64Encode,
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        role,
      }),
    });
    const json = await result.json();

    if (result.status === 401) {
      throw new Error(json.message);
    }
    return json;
  };

  this.getProfilePreference = async function () {
    const result = await fetch(this._baseurl + "/v2/api/lambda/preference", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "x-project": base64Encode,
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    const json = await result.json();

    if (result.status === 401) {
      throw new Error(json.message);
    }

    if (result.status === 403) {
      throw new Error(json.message);
    }
    return json;
  };

  // update email
  this.updateEmail = async function (email) {
    const result = await fetch(this._baseurl + "/v2/api/lambda/update/email", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-project": base64Encode,
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        email,
      }),
    });
    const json = await result.json();

    if (result.status === 401) {
      throw new Error(json.message);
    }

    if (result.status === 403) {
      throw new Error(json.message);
    }
    return json;
  };

  // update password
  this.updatePassword = async function (password) {
    const result = await fetch(
      this._baseurl + "/v2/api/lambda/update/password",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-project": base64Encode,
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          password,
        }),
      }
    );
    const json = await result.json();

    if (result.status === 401) {
      throw new Error(json.message);
    }

    if (result.status === 403) {
      throw new Error(json.message);
    }
    return json;
  };

  // update email
  this.updateEmailByAdmin = async function (email, id) {
    const result = await fetch(
      this._baseurl + "/v2/api/lambda/admin/update/email",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-project": base64Encode,
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          email,
          id,
        }),
      }
    );
    const json = await result.json();

    if (result.status === 401) {
      throw new Error(json.message);
    }

    if (result.status === 403) {
      throw new Error(json.message);
    }
    return json;
  };

  // update password
  this.updatePasswordByAdmin = async function (password, id) {
    const result = await fetch(
      this._baseurl + "/v2/api/lambda/admin/update/password",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-project": base64Encode,
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          password,
          id,
        }),
      }
    );
    const json = await result.json();

    if (result.status === 401) {
      throw new Error(json.message);
    }

    if (result.status === 403) {
      throw new Error(json.message);
    }
    return json;
  };

  this.sendEmailVerification = function () {};
  this.updateEmailVerification = function () {};

  this.setTable = function (table) {
    this._table = table;
  };

  this.getProjectId = function () {
    return this._project_id;
  };

  this.logout = function () {
    window.localStorage.clear();
  };

  this.register = async function (wallet_id) {
    const result = await fetch(this._baseurl + "/v2/api/custom/create", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-project": base64Encode,
      },
      body: JSON.stringify({
        walletId: wallet_id,
      }),
    });
    const json = await result.json();

    console.log("Response Status", result.status);
    //TODO handle wallet connection failed

    if (result.status === 401) {
      throw new Error(json.message);
    }

    if (result.status === 403) {
      throw new Error(json.message);
    }
    return json;
  };

  this.forgot = async function (email) {
    const result = await fetch(this._baseurl + "/v2/api/lambda/forgot", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-project": base64Encode,
      },
      body: JSON.stringify({
        email,
      }),
    });
    const json = await result.json();

    if (result.status === 401) {
      throw new Error(json.message);
    }

    if (result.status === 403) {
      throw new Error(json.message);
    }
    return json;
  };

  this.reset = async function (token, code, password) {
    const result = await fetch(this._baseurl + "/v2/api/lambda/reset", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-project": base64Encode,
      },
      body: JSON.stringify({
        token,
        code,
        password,
      }),
    });
    const json = await result.json();

    if (result.status === 401) {
      throw new Error(json.message);
    }

    if (result.status === 403) {
      throw new Error(json.message);
    }
    return json;
  };

  this.callRestAPI = async function (payload, method) {
    const header = {
      "Content-Type": "application/json",
      "x-project": base64Encode,
      Authorization: "Bearer " + localStorage.getItem("token"),
    };

    switch (method) {
      case "GET":
        const getResult = await fetch(
          this._baseurl + `/v1/api/rest/${this._table}/GET`,
          {
            method: "post",
            headers: header,
            body: JSON.stringify(payload),
          }
        );
        const jsonGet = await getResult.json();

        if (getResult.status === 401) {
          throw new Error(jsonGet.message);
        }

        if (getResult.status === 403) {
          throw new Error(jsonGet.message);
        }
        return jsonGet;
      case "POST":
        const insertResult = await fetch(
          this._baseurl + `/v1/api/rest/${this._table}/${method}`,
          {
            method: "post",
            headers: header,
            body: JSON.stringify(payload),
          }
        );
        const jsonInsert = await insertResult.json();

        if (insertResult.status === 401) {
          throw new Error(jsonInsert.message);
        }

        if (insertResult.status === 403) {
          throw new Error(jsonInsert.message);
        }
        return jsonInsert;
      case "PUT":
        const updateResult = await fetch(
          this._baseurl + `/v1/api/rest/${this._table}/${method}`,
          {
            method: "post",
            headers: header,
            body: JSON.stringify(payload),
          }
        );
        const jsonUpdate = await updateResult.json();

        if (updateResult.status === 401) {
          throw new Error(jsonUpdate.message);
        }

        if (updateResult.status === 403) {
          throw new Error(jsonUpdate.message);
        }
        return jsonUpdate;
      case "DELETE":
        const deleteResult = await fetch(
          this._baseurl + `/v1/api/rest/${this._table}/${method}`,
          {
            method: "post",
            headers: header,
            body: JSON.stringify(payload),
          }
        );
        const jsonDelete = await deleteResult.json();

        if (deleteResult.status === 401) {
          throw new Error(jsonDelete.message);
        }

        if (deleteResult.status === 403) {
          throw new Error(jsonDelete.message);
        }
        return jsonDelete;
      case "DELETEALL":
        const deleteAllResult = await fetch(
          this._baseurl + `/v1/api/rest/${this._table}/${method}`,
          {
            method: "post",
            headers: header,
            body: JSON.stringify(payload),
          }
        );
        const jsonDeleteAll = await deleteAllResult.json();

        if (deleteAllResult.status === 401) {
          throw new Error(jsonDeleteAll.message);
        }

        if (deleteAllResult.status === 403) {
          throw new Error(jsonDeleteAll.message);
        }
        return jsonDeleteAll;
      case "GETALL":
        const getAllResult = await fetch(
          this._baseurl + `/v1/api/rest/${this._table}/${method}`,
          {
            method: "post",
            headers: header,
            body: JSON.stringify(payload),
          }
        );
        const jsonGetAll = await getAllResult.json();

        if (getAllResult.status === 401) {
          throw new Error(jsonGetAll.message);
        }

        if (getAllResult.status === 403) {
          throw new Error(jsonGetAll.message);
        }
        return jsonGetAll;
      case "PAGINATE":
        if (!payload.page) {
          payload.page = 1;
        }
        if (!payload.limit) {
          payload.limit = 10;
        }
        const paginateResult = await fetch(
          this._baseurl + `/v1/api/rest/${this._table}/${method}`,
          {
            method: "post",
            headers: header,
            body: JSON.stringify(payload),
          }
        );
        const jsonPaginate = await paginateResult.json();

        if (paginateResult.status === 401) {
          throw new Error(jsonPaginate.message);
        }

        if (paginateResult.status === 403) {
          throw new Error(jsonPaginate.message);
        }
        return jsonPaginate;
      case "AUTOCOMPLETE":
        const autocompleteResult = await fetch(
          this._baseurl + `/v1/api/rest/${this._table}/${method}`,
          {
            method: "post",
            headers: header,
            body: JSON.stringify(payload),
          }
        );
        const jsonAutocomplete = await autocompleteResult.json();

        if (autocompleteResult.status === 401) {
          throw new Error(jsonAutocomplete.message);
        }

        if (autocompleteResult.status === 403) {
          throw new Error(jsonAutocomplete.message);
        }
        return jsonAutocomplete;
      default:
        break;
    }
  };

  this.callJoinRestAPI = function (payload) {};
  this.subscribe = function (payload) {};
  this.subscribeChannel = function (channel, payload) {};
  this.subscribeListen = function (channel) {};
  this.unSubscribeChannel = function (channel, payload) {};
  this.broadcast = function (payload) {};
  this.exportCSV = function (payload) {};

  this.cmsAdd = async function (page, key, type, value) {
    const header = {
      "Content-Type": "application/json",
      "x-project": base64Encode,
      Authorization: "Bearer " + localStorage.getItem("token"),
    };

    const insertResult = await fetch(this._baseurl + `/v2/api/lambda/cms`, {
      method: "post",
      headers: header,
      body: JSON.stringify({
        page,
        key,
        type,
        value,
      }),
    });
    const jsonInsert = await insertResult.json();

    if (insertResult.status === 401) {
      throw new Error(jsonInsert.message);
    }

    if (insertResult.status === 403) {
      throw new Error(jsonInsert.message);
    }
    return jsonInsert;
  };

  this.cmsEdit = async function (id, page, key, type, value) {
    const header = {
      "Content-Type": "application/json",
      "x-project": base64Encode,
      Authorization: "Bearer " + localStorage.getItem("token"),
    };

    const updateResult = await fetch(
      this._baseurl + `/v2/api/lambda/cms/` + id,
      {
        method: "put",
        headers: header,
        body: JSON.stringify({
          page,
          key,
          type,
          value,
        }),
      }
    );
    const jsonInsert = await updateResult.json();

    if (updateResult.status === 401) {
      throw new Error(jsonInsert.message);
    }

    if (updateResult.status === 403) {
      throw new Error(jsonInsert.message);
    }
    return jsonInsert;
  };

  this.getToken = function () {
    return window.localStorage.getItem("token");
  };

  return this;
}
