import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "chart.js/auto";
import { Pie } from "react-chartjs-2";
import { firstChartConfig, secondChartConfig } from "./chart_data";
import NavigationBar from "./NavigationBar";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { LanguageContext } from "../context/LanguageContext";

//TODO remove react bootstrap and use tailwind
function Home() {
  const { getTranslation } = React.useContext(LanguageContext);

  return (
    <div
      style={{
        color: "white",
        background: "rgb(0,0,0)",
        background:
          "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(78,30,30,1) 50%, rgba(0,0,0,1) 100%)",
      }}
    >
      <NavigationBar />
      <Container>
        <div className="video-wrapper">
          <video className="bg" playsInline autoPlay muted loop>
            <source src="/assets/videos/news.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="header">
            <img
              src="./assets/images/EYE.svg"
              className="red_eye_logo"
              alt="red-eye logo"
            />
            <div className="header-text-font">
              <h1>
                {getTranslation("home_heading_1")}{" "}
                <span className="red_font">
                  {getTranslation("home_heading_2")}
                </span>{" "}
                {getTranslation("home_heading_3")}
              </h1>
              <h1>
                {getTranslation("home_heading_4")}{" "}
                <span className="red_font">
                  {getTranslation("home_heading_5")}
                </span>{" "}
                {getTranslation("home_heading_6")}{" "}
                <span className="red_font">
                  {getTranslation("home_heading_7")}
                </span>
              </h1>
            </div>
            <p style={{ maxWidth: "450px", margin: "0 auto" }}>
              {getTranslation("home_heading_desc")}
            </p>
            <Link to="presubscribe">
              <Button className="pre-subscribe-btn">
                {getTranslation("home_heading_buy_red")}
              </Button>
            </Link>
            <Row className="header_icon_row">
              <Col className="header_icon_col">
                <img
                  src="./assets/images/unedited.svg"
                  className="sub_header_logo"
                  alt="unedited logo"
                />
                <div style={{ paddingLeft: "12px" }}>
                  {getTranslation("home_heading_unedited")},<br />
                  {getTranslation("home_heading_uncut")},<br />
                  {getTranslation("home_heading_unbiased")}
                </div>
              </Col>
              <Col className="header_icon_col">
                <img
                  src="./assets/images/broadcast.svg"
                  className="sub_header_logo"
                  alt="broadcast logo"
                />
                <div style={{ paddingLeft: "12px" }}>
                  {getTranslation("home_heading_b_1")} <br />
                  {getTranslation("home_heading_b_2")}
                  <br />
                  {getTranslation("home_heading_b_3")}
                </div>
              </Col>
              <Col className="header_icon_col">
                <img
                  src="./assets/images/governed.svg"
                  className="unedisub_header_logoted_logo"
                  alt="community governed logo"
                />
                <div style={{ paddingLeft: "12px" }}>
                  {getTranslation("home_heading_community")}
                  <br />
                  {getTranslation("home_heading_governed")}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
      <div className="red-bg">
        <Container className="py-5">
          <Row>
            <Col>
              <h2 className="header-text-font text-center m-5 pb-4">
                {getTranslation("home_become_media")}
              </h2>
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="flex-css" style={{ marginBottom: "120px" }}>
              <div className="impulse-image flex-css">
                <div className="image-container flex-css">
                  <div className="red-eye-circle">
                    <img src="./assets/images/red-eye.svg" alt="red eye logo" />
                    <img
                      style={{ marginTop: "-10px" }}
                      src="./assets/images/red-eye-text.svg"
                      alt="red eye"
                    />
                  </div>
                </div>
                <div className="circle circle1 flex-css">
                  <p className="red-pill">Get paid</p>
                </div>
                <div className="circle circle2 flex-css">
                  <p className="red-pill">Stream</p>
                </div>
                <div className="circle circle3 flex-css">
                  <p className="red-pill">Mint</p>
                </div>
                <div className="circle circle4 flex-css">
                  <p className="red-pill">Distribute</p>
                </div>
                <div className="circle circle5 flex-css">
                  <p className="red-pill">Shoot</p>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="responsive_center">
                <h3>
                  {getTranslation("home_become_media_heading_p1")}
                  <br />
                  {getTranslation("home_become_media_heading_p2")}
                </h3>
                <div
                  style={{
                    maxWidth: "350px",
                    marginTop: "20px",
                    fontSize: "14px",
                    lineHeight: "1.3",
                  }}
                >
                  <p>{getTranslation("home_become_media_p1")}</p>
                  <p>{getTranslation("home_become_media_p2")}</p>
                  <p>{getTranslation("home_become_media_p3")}</p>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "15px" }}
                >
                  <a href="#" target="_blank">
                    <img style={{ width: "120px" }} src="./play-store.png" />
                  </a>
                  <a href="#" target="_blank">
                    <img style={{ width: "120px" }} src="./app-store.png" />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ backgroundColor: "#141414" }}>
        <Container>
          <Row>
            <Col className="mt-4">
              <h2 className="header-text-font text-center mt-5">
                {getTranslation("home_problems_heading_1")}
              </h2>
            </Col>
          </Row>
          <Row className="pb-5" id="problem">
            <Col className="text-center">
              <h3
                style={{ fontSize: "34px" }}
                className="header-text-font text-center mt-5"
              >
                {getTranslation("home_problems_heading_2")}{" "}
                <span className="red_font">
                  {getTranslation("home_problems_heading_3")}
                </span>{" "}
                {getTranslation("home_problems_heading_4")}
              </h3>
              <p style={{ maxWidth: "580px", margin: "0 auto" }}>
                {getTranslation("home_problems_heading_desc")}
              </p>
            </Col>
            <Row className="pt-5 responsive_flex_content">
              <Col lg={6}>
                <h4
                  className="responsive_center_text header-text-font"
                  style={{
                    marginTop: "26px",
                    fontSize: "70px",
                    color: "rgba(255, 255, 255, 0.12)",
                    overflow: "auto",
                  }}
                >
                  {getTranslation("home_problems_heading")}
                </h4>
                <p className="desc">{getTranslation("home_problems_p1")}</p>
                <p className="desc">
                  {getTranslation("home_problems_p2_1")}
                  <span className="red_font">
                    {getTranslation("home_problems_p2_2")}
                  </span>
                </p>
                <p className="desc"> {getTranslation("home_problems_p3")} </p>
                <Row className="pt-3">
                  <Col md={4}>
                    <div className="problem-card">
                      <div className="card-badge">
                        <img
                          src="./assets/images/institution-red-circle.svg"
                          style={{ height: "100%", width: "100%" }}
                        />
                      </div>
                      <p>{getTranslation("home_problems_b_1")}</p>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="problem-card">
                      <div className="card-badge">
                        <img
                          src="./assets/images/people-red-circle.svg"
                          style={{ height: "100%", width: "100%" }}
                        />
                      </div>
                      <p>{getTranslation("home_problems_b_2")}</p>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="problem-card">
                      <div className="card-badge">
                        <img
                          src="./assets/images/network-red-circle.svg"
                          style={{ height: "100%", width: "100%" }}
                        />
                      </div>
                      <p>{getTranslation("home_problems_b_3")}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <div className="problem-card">
                      <div className="card-badge">
                        <img
                          src="./assets/images/book-red-circle.svg"
                          style={{ height: "100%", width: "100%" }}
                        />
                      </div>
                      <p>{getTranslation("home_problems_b_4")}</p>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="problem-card">
                      <div className="card-badge">
                        <img
                          src="./assets/images/video-red-circle.svg"
                          style={{ height: "100%", width: "100%" }}
                        />
                      </div>
                      <p>{getTranslation("home_problems_b_5")}</p>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="problem-card">
                      <div className="card-badge">
                        <img
                          src="./assets/images/tv-red-circle.svg"
                          style={{ height: "100%", width: "100%" }}
                        />
                      </div>
                      <p>{getTranslation("home_problems_b_6")}</p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={6} className="responsive_flex_content">
                <div className="red-hue">
                  <h4
                    className="header-text-font"
                    style={{
                      marginLeft: "10px",
                      marginTop: "10px",
                      fontSize: "70px",
                      color: "rgba(255, 255, 255, 0.12)",
                    }}
                  >
                    {getTranslation("home_challenges_heading")}
                  </h4>
                  <div style={{ marginLeft: "20px", marginTop: "35px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <div className="white-badge">
                        <img
                          src="./assets/images/van-white-circle.svg"
                          style={{ height: "100%", width: "100%" }}
                        />
                      </div>
                      <h4
                        className="raleway-font"
                        style={{ paddingLeft: "15px", marginBottom: 0 }}
                      >
                        {getTranslation("home_challenges_heading_1")}
                      </h4>
                    </div>
                    <p>{getTranslation("home_challenges_b_1_1")}</p>
                    <p>{getTranslation("home_challenges_b_1_2")}</p>
                  </div>
                  <div style={{ marginLeft: "20px", marginTop: "35px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <div className="white-badge">
                        <img
                          src="./assets/images/face-white-circle.svg"
                          style={{ height: "100%", width: "100%" }}
                        />
                      </div>
                      <h4
                        className="raleway-font"
                        style={{ paddingLeft: "15px", marginBottom: 0 }}
                      >
                        {getTranslation("home_challenges_heading_2")}
                      </h4>
                    </div>
                    <p>{getTranslation("home_challenges_b_2")}</p>
                  </div>
                  <div style={{ marginLeft: "20px", marginTop: "35px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <div className="white-badge">
                        <img
                          src="./assets/images/newspaper-white-circle.svg"
                          style={{ height: "100%", width: "100%" }}
                        />
                      </div>
                      <h4
                        className="raleway-font"
                        style={{ paddingLeft: "15px", marginBottom: 0 }}
                      >
                        {getTranslation("home_challenges_heading_3")}
                      </h4>
                    </div>
                    <p>{getTranslation("home_challenges_b_3_1")}</p>
                    <p>{getTranslation("home_challenges_b_3_2")}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Row>
        </Container>
      </div>
      <div style={{ backgroundColor: "#fff", color: "#000" }}>
        <Container>
          <Row id="solution">
            <Col className="mt-4">
              <h2 className="header-text-font text-center mt-5">
                {getTranslation("home_solution_heading_1")}{" "}
                <span className="red_font">
                  {getTranslation("home_solution_heading_1")}
                </span>
              </h2>
            </Col>
          </Row>
          <Row className="pb-5" id="problem">
            <Col className="text-center">
              <p style={{ maxWidth: "630px", margin: "0 auto" }}>
                {getTranslation("home_solution_desc")}
              </p>
            </Col>
            <Row className="pt-5 responsive_flex_content">
              <Col lg={6}>
                <div>
                  <div style={{ marginLeft: "20px", marginTop: "25px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <div className="white-badge">
                        <img
                          src="./assets/images/phone-white.svg"
                          style={{ height: "30px", width: "30px" }}
                        />
                      </div>
                      <h4
                        className="raleway-font"
                        style={{ paddingLeft: "15px", marginBottom: 0 }}
                      >
                        {getTranslation("home_solution_sub_heading_1")}
                      </h4>
                    </div>
                    <p>{getTranslation("home_solution_sub_p1_1")}</p>
                    <p>{getTranslation("home_solution_sub_p1_2")}</p>
                  </div>
                  <div style={{ marginLeft: "20px", marginTop: "35px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <div className="white-badge">
                        <img
                          src="./assets/images/eye-white.svg"
                          style={{ height: "30px", width: "30px" }}
                        />
                      </div>
                      <h4
                        className="raleway-font"
                        style={{ paddingLeft: "15px", marginBottom: 0 }}
                      >
                        {getTranslation("home_solution_sub_heading_2")}
                      </h4>
                    </div>
                    <p>{getTranslation("home_solution_sub_p2_1")}</p>
                    <p>{getTranslation("home_solution_sub_p2_2")}</p>
                  </div>
                  <div style={{ marginLeft: "20px", marginTop: "35px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <div className="white-badge">
                        <img
                          src="./assets/images/mic-white.svg"
                          style={{ height: "30px", width: "30px" }}
                        />
                      </div>
                      <h4
                        className="raleway-font"
                        style={{ paddingLeft: "15px", marginBottom: 0 }}
                      >
                        {getTranslation("home_solution_sub_heading_3")}
                      </h4>
                    </div>
                    <p>{getTranslation("home_solution_sub_p3_1")}</p>
                    <p>{getTranslation("home_solution_sub_p3_2")}</p>
                    <p>{getTranslation("home_solution_sub_p3_3")}</p>
                  </div>
                  <div style={{ marginLeft: "20px", marginTop: "35px" }}>
                    <Link to="presubscribe">
                      <Button className="pre-subscribe-btn">
                        {getTranslation("home_solution_buy_red")}
                      </Button>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <h4
                  className="responsive_center_text header-text-font red_font"
                  style={{ marginTop: "26px", fontSize: "30px" }}
                >
                  {getTranslation("home_solution_sub_heading_4")}
                </h4>
                <p>{getTranslation("home_solution_sub_p4_1")}</p>
                <p>{getTranslation("home_solution_sub_p4_2")}</p>
                <p>{getTranslation("home_solution_sub_p4_3")}</p>
                <Row className="mt-5">
                  <Col md={4}>
                    <div className="solution-card" style={{ height: "200px" }}>
                      <div className="white-card-badge">
                        <img
                          src="./assets/images/phone-white-circle.svg"
                          style={{ height: "100%", width: "100%" }}
                        />
                      </div>
                      <p>{getTranslation("home_solution_sub_b_1")}</p>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="solution-card" style={{ height: "200px" }}>
                      <div className="white-card-badge">
                        <img
                          src="./assets/images/video-white-circle.svg"
                          style={{ height: "100%", width: "100%" }}
                        />
                      </div>
                      <p>{getTranslation("home_solution_sub_b_2")}</p>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="solution-card" style={{ height: "200px" }}>
                      <div className="white-card-badge">
                        <img
                          src="./assets/images/location-white-circle.svg"
                          style={{ height: "100%", width: "100%" }}
                        />
                      </div>
                      <p>{getTranslation("home_solution_sub_b_3")}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={8}>
                    <Row>
                      <Col md={6}>
                        <div className="solution-card">
                          <div className="white-card-badge">
                            <img
                              src="./assets/images/satellite-white-circle.svg"
                              style={{ height: "100%", width: "100%" }}
                            />
                          </div>
                          <p>{getTranslation("home_solution_sub_b_4")}</p>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="solution-card">
                          <div className="white-card-badge">
                            <img
                              src="./assets/images/star-white-circle.svg"
                              style={{ height: "100%", width: "100%" }}
                            />
                          </div>
                          <p>{getTranslation("home_solution_sub_b_5")}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="solution-card">
                          <div className="white-card-badge">
                            <img
                              src="./assets/images/check-white-circle.svg"
                              style={{ height: "100%", width: "100%" }}
                            />
                          </div>
                          <p>{getTranslation("home_solution_sub_b_6")}</p>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="solution-card">
                          <div className="white-card-badge">
                            <img
                              src="./assets/images/tag-white-circle.svg"
                              style={{ height: "100%", width: "100%" }}
                            />
                          </div>
                          <p>{getTranslation("home_solution_sub_b_7")}</p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={4}>
                    <Col>
                      <div
                        className="solution-card last_card"
                        style={{ height: "450px" }}
                      >
                        <div className="white-card-badge">
                          <img
                            src="./assets/images/vote-white-circle.svg"
                            style={{
                              height: "100%",
                              width: "100%",
                              padding: "8px",
                            }}
                          />
                        </div>
                        <p>{getTranslation("home_solution_sub_b_8")}</p>
                      </div>
                    </Col>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
        </Container>
      </div>
      <div style={{ background: "#141414" }} className="pb-5">
        <Container>
          <Row id="pre" className="pb-5">
            <Col className="text-center">
              <Row>
                <Col className="mt-4">
                  <h2 className="header-text-font text-center mt-5">
                    {getTranslation("home_token_heading_1")}{" "}
                    <span className="red_font">
                      {getTranslation("home_token_heading_2")}
                    </span>
                  </h2>
                </Col>
              </Row>
              <Row className="response_vertical_flex">
                <Col
                  style={{
                    textAlign: "left",
                    display: "flex",
                    justfyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <h2 className="mt-4">
                    {getTranslation("home_token_sub_heading_1")}
                  </h2>
                  <p className="mt-4">
                    {getTranslation("home_token_sub_p1_1")}{" "}
                    <span className="red_font">
                      {getTranslation("home_token_sub_p1_2")}
                    </span>{" "}
                    {getTranslation("home_token_sub_p1_3")}{" "}
                    <span className="red_font">
                      {getTranslation("home_token_sub_p1_4")}
                    </span>{" "}
                    {getTranslation("home_token_sub_p1_5")}{" "}
                    <span className="red_font">
                      {getTranslation("home_token_sub_p1_6")}
                    </span>{" "}
                    {getTranslation("home_token_sub_p1_7")}{" "}
                    <span className="red_font">
                      {getTranslation("home_token_sub_p1_8")}
                    </span>{" "}
                    {getTranslation("home_token_sub_p1_9")}{" "}
                    <span className="red_font">
                      {getTranslation("home_token_sub_p1_10")}
                    </span>
                    {getTranslation("home_token_sub_p1_11")}
                  </p>
                </Col>
                <Col>
                  <div
                    style={{
                      background:
                        "radial-gradient(49.99% 50% at 50% 50%, rgba(0, 0, 0, 0) 55%, #141414 100%)",
                    }}
                  >
                    <img
                      src="./assets/images/oval-coin.png"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="response_vertical_flex">
                <Col>
                  <div
                    style={{
                      background:
                        "radial-gradient(49.99% 50% at 50% 50%, rgba(0, 0, 0, 0) 55%, #141414 100%)",
                    }}
                  >
                    <img
                      src="./assets/images/nfts.svg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
                <Col
                  style={{
                    textAlign: "left",
                    display: "flex",
                    justfyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <h2 className="mt-5">
                    {getTranslation("home_token_sub_heading_2")}
                  </h2>
                  <p className="mt-4">
                    {getTranslation("home_token_sub_p2_1")}{" "}
                    <span className="red_font">
                      {getTranslation("home_token_sub_p2_2")}
                    </span>{" "}
                    {getTranslation("home_token_sub_p2_3")}
                  </p>
                  <p>{getTranslation("home_token_sub_p2_4")}</p>
                  <p>{getTranslation("home_token_sub_p2_5")}</p>
                </Col>
              </Row>
            </Col>
            <Row className="responsive_flex_content">
              <h2 className="mt-4">
                {getTranslation("home_token_sub_heading_3")}
              </h2>
              <Row className="mt-5 responsive_flex_content">
                <Col lg={3}>
                  <div
                    className="pre-sub-card"
                    style={{
                      paddingLeft: "30px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <h4
                      className="red_font"
                      style={{ fontSize: "14px", fontWeight: "bolder" }}
                    >
                      {getTranslation("home_presub_start_date")}
                    </h4>
                    <p
                      style={{
                        marginBottom: 0,
                        fontWeight: "400",
                        fontSize: "20px",
                      }}
                    >
                      {getTranslation("home_presub_completed")}
                    </p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div
                    className="pre-sub-card"
                    style={{
                      paddingLeft: "30px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <h4
                      className="red_font"
                      style={{ fontSize: "14px", fontWeight: "bolder" }}
                    >
                      {getTranslation("home_presub_end_date")}
                    </h4>
                    <p
                      style={{
                        marginBottom: 0,
                        fontWeight: "400",
                        fontSize: "20px",
                      }}
                    >
                      {getTranslation("home_presub_completed")}
                    </p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div
                    className="pre-sub-card"
                    style={{
                      paddingLeft: "30px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <h4
                      className="red_font"
                      style={{ fontSize: "14px", fontWeight: "bolder" }}
                    >
                      {getTranslation("home_presub_available_tokens")}
                    </h4>
                    <p
                      style={{
                        marginBottom: 0,
                        fontWeight: "400",
                        fontSize: "20px",
                      }}
                    >
                      0 RED
                    </p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div
                    className="pre-sub-card"
                    style={{
                      paddingLeft: "30px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <h4
                      className="red_font"
                      style={{ fontSize: "14px", fontWeight: "bolder" }}
                    >
                      {getTranslation("home_presub_accepted_currency")}
                    </h4>
                    <p
                      style={{
                        marginBottom: 0,
                        fontWeight: "400",
                        fontSize: "20px",
                      }}
                    >
                      <img
                        src="./assets/images/bnb-coin.svg"
                        style={{ width: "20px", height: "20px" }}
                      />{" "}
                      BNB{" "}
                    </p>
                  </div>
                </Col>

                <Col lg={3}>
                  <div
                    className="pre-sub-card"
                    style={{
                      paddingLeft: "30px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <h4
                      className="red_font"
                      style={{ fontSize: "14px", fontWeight: "bolder" }}
                    >
                      {getTranslation("home_presub_presub_rates")}
                    </h4>
                    <p
                      style={{
                        marginBottom: 0,
                        fontWeight: "400",
                        fontSize: "20px",
                      }}
                    >
                      $0.001 - $0.006 RED
                    </p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div
                    className="pre-sub-card"
                    style={{
                      paddingLeft: "30px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <h4
                      className="red_font"
                      style={{ fontSize: "14px", fontWeight: "bolder" }}
                    >
                      {getTranslation("home_presub_minimum_amts")}
                    </h4>
                    <p
                      style={{
                        marginBottom: 0,
                        fontWeight: "400",
                        fontSize: "20px",
                      }}
                    >
                      $1/$5K/$10K
                    </p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div
                    className="pre-sub-card"
                    style={{
                      paddingLeft: "30px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <h4
                      className="red_font"
                      style={{ fontSize: "14px", fontWeight: "bolder" }}
                    >
                      {getTranslation("home_presub_maximum_amts")}
                    </h4>
                    <p
                      style={{
                        marginBottom: 0,
                        fontWeight: "400",
                        fontSize: "20px",
                      }}
                    >
                      $5K/$24K/$100K
                    </p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div
                    className="pre-sub-card"
                    style={{
                      paddingLeft: "30px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <h4
                      className="red_font"
                      style={{ fontSize: "14px", fontWeight: "bolder" }}
                    >
                      {getTranslation("home_presub_audited_by")}
                    </h4>
                    <p
                      style={{
                        marginBottom: 0,
                        fontWeight: "400",
                        fontSize: "20px",
                      }}
                    >
                      {getTranslation("home_presub_zeppelin")}
                    </p>
                  </div>
                </Col>
              </Row>
            </Row>
            <div
              className="mt-5 repsonsive_to_block"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Button className="upload-news-btn">
                {getTranslation("home_presub_join")}
              </Button>
              <a style={{ marginLeft: "30px", fontSize: "14px" }}>
                {getTranslation("home_presub_period_closed")}
              </a>{" "}
            </div>
          </Row>

          <Row className="mt-4">
            <Col md={3} className="responsive_center_text">
              <img
                src="./assets/images/institutional.svg"
                style={{ width: "60px", height: "60px", marginBottom: "20px" }}
              />
              <h4>
                {getTranslation("home_presub_institutional")}
                <br />
                Pre-Sub
              </h4>
              <div style={{ color: "#f1f1f1", fontWeight: "300" }}>
                <p>
                  {getTranslation("home_presub_date")}
                  <br />
                  {getTranslation("home_presub_completed")}
                </p>
                <p>{getTranslation("home_presub")} @ $0.001/RED</p>
                <p>
                  Total USD
                  <br />
                  130K
                </p>
              </div>
            </Col>
            <Col md={3} className="responsive_center_text">
              <img
                src="./assets/images/creator.svg"
                style={{ width: "60px", height: "60px", marginBottom: "20px" }}
              />
              <h4>
                {getTranslation("home_presub_creator")}
                <br />
                Pre-Sub
              </h4>
              <div style={{ color: "#f1f1f1", fontWeight: "300" }}>
                <p>
                  {getTranslation("home_presub_date")}
                  <br />
                  {getTranslation("home_presub_completed")}
                </p>
                <p>{getTranslation("home_presub")} @ $0.004/RED</p>
                <p>
                  Total USD
                  <br />
                  25K
                </p>
              </div>
            </Col>
            <Col md={3} className="responsive_center_text">
              <img
                src="./assets/images/basic.svg"
                style={{ width: "50px", height: "60px", marginBottom: "20px" }}
              />
              <h4>
                {getTranslation("home_presub_basic")}
                <br />
                Pre-Sub
              </h4>
              <div style={{ color: "#f1f1f1", fontWeight: "300" }}>
                <p>
                  {getTranslation("home_presub_basic_date")}
                  <br />
                  10,000,000,000 RED
                </p>
                <p>{getTranslation("home_presub")} @ $0.006/RED</p>
                <p>
                  {getTranslation("home_presub_goal")} USD
                  <br />
                  2,000,000
                </p>
              </div>
            </Col>
            <Col md={3} className="responsive_center_text">
              <img
                src="./assets/images/exchange.svg"
                style={{ width: "60px", height: "60px", marginBottom: "20px" }}
              />
              <h4>
                {getTranslation("home_presub_public")}
                <br />
                {getTranslation("home_presub_listing")}
              </h4>
              <div style={{ color: "#f1f1f1", fontWeight: "300" }}>
                <p>
                  {getTranslation("home_presub_list_date")}
                  <br />
                  20,000,000,000 RED
                </p>
                <p>@ TBD</p>
                <p>
                  Total USD
                  <br />
                  TBD
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ background: "#212121" }}>
        <Container id="distribution" className="text-center pb-5">
          <Row>
            <Col className="mt-4">
              <h2 className="header-text-font text-center mt-5">
                {getTranslation("home_distribution_1")}{" "}
                <span className="red_font">
                  {getTranslation("home_distribution_2")}
                </span>{" "}
                {getTranslation("home_distribution_3")}{" "}
                <span className="red_font">
                  {getTranslation("home_distribution_4")}
                </span>
              </h2>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="lg-6">
              <div style={{ padding: "10%" }}>
                <Pie
                  options={firstChartConfig.options}
                  data={firstChartConfig.data}
                />
                <h4 className="my-5">{getTranslation("home_initial_dist")}</h4>
              </div>
            </Col>
            <Col className="lg-6">
              <div style={{ padding: "10%" }}>
                <Pie
                  options={secondChartConfig.options}
                  data={secondChartConfig.data}
                />
                <h4 className="my-5">{getTranslation("home_fund_alloc")}</h4>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="container-fluid timeline-container vector-parent pt-5 px-5">
        <div className="timeline-heading">
          <h1 className="text-center mb-3 raleway-font">
            {getTranslation("home_card_heading_1")}
          </h1>
          <p className="text-center">
            {getTranslation("home_card_heading_2")}
            <br />
            {getTranslation("home_card_heading_3")}
          </p>
        </div>
        <div className="timeline py-5">
          <div className="container-box left timeline-card">
            <div
              className="content"
              style={{ backgroundColor: "#BD1550", width: "255px" }}
            >
              <img src="./assets/images/tl1.svg" />
              <h2>2014</h2>
              <p>{getTranslation("home_card_1")}</p>
            </div>
          </div>
          <div className="container-box right timeline-card">
            <div
              className="content"
              style={{ backgroundColor: "#E97F02", width: "255px" }}
            >
              <img src="./assets/images/tl2.svg" />
              <h2>2015 - 2019</h2>
              <p>{getTranslation("home_card_2")}</p>
            </div>
          </div>
          <div className="container-box left event-starts timeline-card">
            <div
              className="content"
              style={{ backgroundColor: "#00633E", width: "255px" }}
            >
              <img src="./assets/images/tl3.svg" />
              <h2>Q1 2020</h2>
              <p>{getTranslation("home_card_3")}</p>
            </div>
          </div>
          <div className="container-box right timeline-card">
            <div
              className="content"
              style={{ backgroundColor: "#F8CA00", width: "255px" }}
            >
              <img src="./assets/images/tl4.svg" />
              <h2>Q2 2020</h2>
              <p>{getTranslation("home_card_4")}</p>
            </div>
          </div>
          <div className="container-box right event-starts timeline-card">
            <div
              className="content"
              style={{ backgroundColor: "#C53A53", width: "255px" }}
            >
              <img src="./assets/images/tl5.png" />
              <h2>2021</h2>
              <p>{getTranslation("home_card_5")}</p>
            </div>
          </div>
          <div className="container-box left timeline-card">
            <div
              className="content"
              style={{ backgroundColor: "#0F6B9E", width: "255px" }}
            >
              <img src="./assets/images/tl6.svg" />
              <h2>2021</h2>
              <p>{getTranslation("home_card_6")}</p>
            </div>
          </div>
          <div className="container-box right timeline-card">
            <div
              className="content"
              style={{ backgroundColor: "#2A023F", width: "255px" }}
            >
              <img src="./assets/images/tl7.png" />
              <h2>2022</h2>
              <p>{getTranslation("home_card_7")}</p>
            </div>
          </div>
          <div className="container-box left timeline-card">
            <div
              className="content"
              style={{ backgroundColor: "#A11B74", width: "255px" }}
            >
              <img src="./assets/images/tl8.png" />
              <h2>2022</h2>
              <p>{getTranslation("home_card_8")}</p>
            </div>
          </div>
          <div className="container-box right timeline-card">
            <div
              className="content"
              style={{ backgroundColor: "#1A50A1", width: "255px" }}
            >
              <img src="./assets/images/tl9.svg" />
              <h2>2022</h2>
              <p>{getTranslation("home_card_9")}</p>
            </div>
          </div>
          <div
            className="timeline-point first-point flex-css"
            style={{ backgroundColor: "#4F0A42" }}
          >
            <p>2014</p>
          </div>
          <div
            className="timeline-point second-point flex-css"
            style={{ backgroundColor: "#4047AA" }}
          >
            <p>2020</p>
          </div>
          <div
            className="timeline-point third-point flex-css"
            style={{ backgroundColor: "#97AA13" }}
          >
            <p>2021</p>
          </div>
          <div className="timeline-point end-circle flex-css">
            <div className="circle circle1 flex-css">
              <div className="circle circle2 flex-css">
                <div className="circle circle3 flex-css"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="vector-child timeline-vector-rect1"></div>
        <div className="vector-child timeline-vector-rect2"></div>
        <div className="vector-child timeline-vector-rect3"></div>
        <div className="vector-child timeline-vector-rect4"></div>
      </div>
      <div style={{ backgroundColor: "#141414" }} className="pb-5">
        <Container>
          <Row className="py-4">
            <Col lg={5}>
              <h3 className="my-5">
                {getTranslation("home_bullet_heading_p1")}
                <br />
                {getTranslation("home_bullet_heading_p2")}
              </h3>
              <div
                className="my-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src="./assets/images/eye-bullet.svg"
                  style={{ width: "30px" }}
                />
                <p style={{ marginBottom: 0, marginLeft: "15px" }}>
                  {getTranslation("home_bullet_1")}
                </p>
              </div>
              <div
                className="my-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src="./assets/images/eye-bullet.svg"
                  style={{ width: "30px" }}
                />
                <p style={{ marginBottom: 0, marginLeft: "15px" }}>
                  {getTranslation("home_bullet_2")}
                </p>
              </div>
              <div
                className="my-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src="./assets/images/eye-bullet.svg"
                  style={{ width: "30px" }}
                />
                <p style={{ marginBottom: 0, marginLeft: "15px" }}>
                  {getTranslation("home_bullet_3")}
                </p>
              </div>
              <div
                className="my-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src="./assets/images/eye-bullet.svg"
                  style={{ width: "30px" }}
                />
                <p style={{ marginBottom: 0, marginLeft: "15px" }}>
                  {getTranslation("home_bullet_4")}
                </p>
              </div>
              <div
                className="my-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src="./assets/images/eye-bullet.svg"
                  style={{ width: "30px" }}
                />
                <p style={{ marginBottom: 0, marginLeft: "15px" }}>
                  {getTranslation("home_bullet_5")}
                </p>
              </div>
              <div
                className="my-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src="./assets/images/eye-bullet.svg"
                  style={{ width: "30px" }}
                />
                <p style={{ marginBottom: 0, marginLeft: "15px" }}>
                  {getTranslation("home_bullet_6")}
                </p>
              </div>
              <div
                className="my-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src="./assets/images/eye-bullet.svg"
                  style={{ width: "30px" }}
                />
                <p style={{ marginBottom: 0, marginLeft: "15px" }}>
                  {getTranslation("home_bullet_7")}
                </p>
              </div>
            </Col>
            <Col lg={7} style={{ display: "flex" }}>
              <img
                src="/assets/images/iphone.svg"
                alt="nft"
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
