import { createContext, useContext, useEffect, useState } from "react";
import { translations } from "./Translations";

//TODO Refactor language to use context properly
export const LanguageContext = createContext();

export function LanguageProvider({ children }) {
  const localSetting = localStorage.getItem("redeyed-lang");

  let currentLanguage = "en";

  if (localSetting && localSetting === "esp") {
    currentLanguage = "esp";
  }

  const [language, setLanguage] = useState(currentLanguage);

  const getTranslation = (key) => {
    return translations[language][key];
  };

  useEffect(() => {
    if (language !== localStorage.getItem("redeyed-lang")) {
      localStorage.setItem("redeyed-lang", language);
    }
  }, [language]);

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage,
        getTranslation,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  const context = useContext(LanguageContext);

  if (!context)
    throw new Error("useLanguage must be used inside a `LanguageProvider`");

  return context;
}
